import { render, staticRenderFns } from "./UICreatePostModal.vue?vue&type=template&id=b1ab3572"
import script from "./UICreatePostModal.vue?vue&type=script&lang=js"
export * from "./UICreatePostModal.vue?vue&type=script&lang=js"
import style0 from "./UICreatePostModal.vue?vue&type=style&index=0&id=b1ab3572&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default})
