import { findAndReplace } from '@/utils';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  GET_FEED_START: (state) => {
    state.feed = [];
    state.newPostNumber = null;
    state.error = null;
    state.feedLoading = true;
  },
  GET_FEED_SUCCESS: (state, result) => {
    const { data, cursor } = result;

    state.cursor = cursor;
    state.feed = data;
    state.feedLoading = false;
  },
  GET_FEED_ERROR: (state, error) => {
    state.error = error;
    state.feedLoading = false;
  },
  COUNT_NEW_POST_SUCCESS: (state, data) => {
    state.newPostNumber = data;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
    showErrorToast(window.$nuxt.$t('error_home_page'));
  },
  UPDATE_POST: (state, payload) => {
    state.feed = findAndReplace(state.feed, 'postId', payload.postId, payload);
  },
  UPDATE_POST_AFTER_EDIT: (state, post) => {
    const postIndex = state.feed.findIndex((p) => p.postId === post.postId);

    if (postIndex !== -1) {
      state.feed.splice(postIndex, 1, post);
    }
  },
  INCREMENT_POST_REPLY_COUNT: (state, postId) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === postId);

      if (post) {
        post.replyCount++;
      }
    }
  },
  APPEND_POSTS_TO_FEED: (state, payload) => {
    const { data, cursor } = payload;

    if ((state.feed?.length ?? 0) > 0 && (data?.length ?? 0) > 0) {
      const feedUpdatedDates = new Map(
        state.feed?.map((p) => [p.postId, p.updatedDate]) ?? []
      );

      const uniqueNewPostsToAppend = [];

      // Deduplicate posts by post id.
      // Replace existing posts in case the newly fetched post has a more recent updatedDate
      data.forEach((p) => {
        const existingUpdatedDate = feedUpdatedDates.get(p.postId);

        // If a post with the same id exists in the feed, and the new post is more recent, replace the existing post.
        if (existingUpdatedDate) {
          console.debug(
            `Post with id ${p.postId} : ${p.updatedDate} already exists in the feed with updated date ${existingUpdatedDate}.`
          );

          if (
            p.updatedDate &&
            new Date(p.updatedDate) > new Date(existingUpdatedDate)
          ) {
            console.debug(`Replacing post ${p.postId} with more recent data`);

            const existingFeedItemIndex = state.feed.findIndex(
              (f) => f.postId === p.postId
            );

            if (existingFeedItemIndex !== -1) {
              state.feed.splice(existingFeedItemIndex, 1, p);
              feedUpdatedDates.set(p.postId, p.updatedDate);
            } else
              console.error(
                `Unexpected error occurred. Post with id ${p.postId} is suddenly not found in the feed. Skipping replacement.`
              );
          }
        } else {
          uniqueNewPostsToAppend.push(p);
        }
      });

      state.feed = [...state.feed, ...uniqueNewPostsToAppend];
    }

    state.cursor = cursor;
  },
  APPEND_NEW_POST_AT_TOP: (state, post) => {
    if (state.feed) state.feed = [post, ...state.feed];
  },
  SET_FEED_LOADING: (state, isLoading) => {
    state.feedLoading = isLoading;
  },
  SET_NEXT_POSTS_LOADING: (state, isLoading) => {
    state.nextPostsLoading = isLoading;
  },
  SET_REPLIES_LOADING: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesLoading = payload.isLoading;
    }
  },
  SET_REPLIES_ERROR: (state, payload) => {
    const post = state.feed.find((e) => e.postId === payload.target);
    if (post) {
      post.repliesError = payload.err;
      //TODO : add some toast
    }
  },
  APPEND_NEW_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.target);
      if (post) {
        if (!post.replies) {
          post.replies = [];
        }
        const newArray = post.replies.slice(0, 1);
        post.replies = [payload, ...newArray];
        post.replyCount++;
      }
    }
  },
  UPDATE_REPLY: (state, data) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === data.target);
      if (post) {
        const index = post.replies.findIndex((e) => e.postId === data.postId);
        const res = [...post.replies];
        res[index] = data;

        post.replies = res;
      }
    }
  },
  INCREMENT_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const index = post.replies.findIndex(
          (e) => e.postId === payload.replyId
        );

        post.replies[index].replyCount++;
      }
    }
  },
  UPDATE_POST_LIKE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isLiked = payload.isLiked;
        post.likeCount = payload.likeCount;
      }
    }
  },
  UPDATE_POST_FAVORITE: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        post.isFavorited = payload.isFavorited;
      }
    }
  },
  /// Nested Replies
  SET_NESTED_REPLIES: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = payload.replies;
        }
      }
    }
  },
  APPEND_NESTED_REPLY_BOTTOM: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          reply.replies = [...reply.replies, ...payload.replies];
        }
      }
    }
  },
  APPEND_NESTED_REPLY_TOP: (state, payload) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === payload.postId);

      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          if (!reply.replies) {
            reply.replies = [payload.data];
          } else {
            reply.replies = [payload.data, ...reply.replies];
          }
        }
      }
    }
  },
  UPDATE_NESTED_REPLY: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find(
          (e) => e.postId === payload.data.target
        );

        if (reply) {
          const index = reply.replies.findIndex(
            (e) => e.postId === payload.data.postId
          );
          const res = [...reply.replies];
          res[index] = payload.data;

          reply.replies = res;
        }
      }
    }
  },
  INCREMENT_NESTED_REPLY_REPLY_COUNT: (state, payload) => {
    if (state.feed) {
      // Finding the post
      const post = state.feed.find((e) => e.postId === payload.postId);
      if (post) {
        const reply = post.replies.find((e) => e.postId === payload.replyId);

        if (reply) {
          const nestedReply = reply.replies.find(
            (e) => e.postId === payload.nestedReplyId
          );
          if (nestedReply) nestedReply.replyCount++;
        }
      }
    }
  },
};
