export enum ComponentIds {
  FeedStartAPost = 'FeedStartAPost',
  WidgetsContainerId = 'WidgetsContainerId',
  SearchbarId = 'SearchbarId',
  ProfileBoxId = 'ProfileBoxId',
  MarketFeedContainerId = 'MarketFeedContainerId',
  CryptoFeedContainerId = 'CryptoFeedContainerId',
  FavoriteContainerId = 'FavoriteContainerId',
  MessagesContainerId = 'MessagesContainerId',
  NavbarContainerId = 'NavbarContainerId',
  PortfolioPrivacyBtn = 'portfolioPrivacyBtn',
  NavProfile = 'navProfile',
  FirstPostFromFeed = 'firstPostFromFeed',
  OnboardingContainerId = 'OnboardingContainerId',
  AcademyContainerId = 'AcademyContainerId',
}
