
import { VEmojiPicker } from 'v-emoji-picker';
import { getMedia } from '@/utils/mixins';
import MediaBar from '@/components/ui/molecule/MediaBar';
import GiphyModal from '@/components/ui/molecule/GiphyModal';
import MultipleImageDisplay from '@/components/ui/molecule/MultipleImageDisplay';
import PostBox from '@/components/ui/molecule/PostBox';
import { PostDisabledReason } from '~/types/models/post';

export default {
  name: 'UICreateComment',
  components: {
    VEmojiPicker,
    MediaBar,
    GiphyModal,
    MultipleImageDisplay,
    PostBox,
  },
  mixins: [getMedia],
  props: {
    searchMentions: {
      type: Function,
      required: true,
    },
    searchHashtags: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploadImage: {
      type: Function,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    profileAvatarUri: {
      type: String,
      default: null,
    },
    targetType: {
      type: String,
      required: false,
      default: 'POST',
    },
    showProfileImage: {
      type: Boolean,
      default: true,
    },
    topMargin: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      editor: null,
      currentMedia: '',
      imagesToUpload: null,
      disabledByContent: false,
      disabledTooltip: null,
      gifToUpload: null,
      currentEditorIndex: null,
      /**
       * @description Inner width of the window
       * Used to determine if the user is on mobile or not.
       *
       * @default window.innerWidth
       * @type {number}
       */
      windowWidth: window.innerWidth,
      postBoxKey: 0,
      embed: null,
    };
  },
  computed: {
    imagesToDisplay() {
      if (this.gifToUpload) {
        return [{ src: this.gifToUpload.src }];
      } else if (this.imagesToUpload) {
        return this.imagesToUpload.map((image) => ({
          src: image.preview,
        }));
      } else {
        return null;
      }
    },
    disabledMedia() {
      if (this.gifToUpload) {
        return ['image'];
      } else if (this.imagesToUpload && this.imagesToUpload.length) {
        return ['gif'];
      }

      if (this.disabled) {
        return ['image', 'gif', 'emoji'];
      }
      return [];
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    getContainerClasses() {
      return `mt-${this.topMargin}`;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.editor.setText('');
        this.imagesToUpload = null;
        this.gifToUpload = null;
        this.currentMedia = '';
      }
    },
  },
  mounted() {
    // Update window width on resize so that the computed property is updated
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    resetPostBox() {
      this.postBoxKey += 1;
    },
    submitPost() {
      const images = this.getImageFormData();
      const mentions = this.getMentions();
      const body = this.getBody(mentions);

      if (body || images || this.gifToUpload || mentions.length) {
        this.$emit('onSubmit', {
          body: body,
          mentions,
          gif: this.gifToUpload ? this.gifToUpload.id : null,
          images,
          targetType: this.targetType,
          target: this.target,
          embed: this.embed,
        });
        this.resetPostBox();
      }
    },
    getBody(mentions = []) {
      let body = this.editor
        .getContents()
        .ops.map((op) => {
          if (typeof op.insert === 'string') {
            return op.insert;
          }
          if (op.insert.mention) {
            return op.insert.mention.denotationChar + op.insert.mention.value;
          }
        })
        .join('')
        .replace(/\n$/, '');

      mentions.forEach((mention) => {
        if (mention.type === '@') {
          body = body.replace(mention.value, mention.target);
        }
      });
      return body;
    },
    getMentions() {
      const contents = this.editor.getContents();
      const res = [];

      contents.forEach((e) => {
        if (e.insert && e.insert.mention) {
          res.push({
            type: e.insert.mention.denotationChar,
            target: e.insert.mention.id,
            value: e.insert.mention.value,
          });
        }
      });

      if (this.mentionedPost) {
        res.push({ type: 'POST', target: this.mentionedPost.postId });
      }

      return res;
    },
    getImageFormData() {
      if (this.imagesToUpload && this.imagesToUpload.length) {
        return this.imagesToUpload.map((e) => e.id);
      } else {
        return null;
      }
    },
    async handleImage(file) {
      if ((this.imagesToUpload || []).length + 1 > 4) {
        this.$toast.error(this.$t('max_image_to_upload'));
      } else {
        file = await this.uploadImage(file);
        this.imagesToUpload = [...(this.imagesToUpload || []), file];
      }
    },
    removeImage(index) {
      if (this.gifToUpload) {
        this.gifToUpload = null;
      } else {
        this.$emit('removeImage', this.imagesToUpload[index].id);
        this.imagesToUpload.splice(index, 1);
      }
    },
    handleSelectMedia(selectedMedia) {
      if (this.currentMedia === selectedMedia) {
        this.currentMedia = '';
      } else {
        this.currentMedia = selectedMedia;
      }
    },
    selectEmoji(emoji) {
      const tmpIndex = this.currentEditorIndex || 0;
      this.editor.insertText(this.currentEditorIndex || 0, emoji.data, 'user');
      this.currentEditorIndex = tmpIndex + emoji.data.length;
      this.currentMedia = '';
    },
    handleClickOutsideEmojiPicker() {
      this.currentMedia = '';
    },
    onClickGIF(gif) {
      this.gifToUpload = gif;
      this.currentMedia = '';
    },
    calcIndexWithMentions() {
      const textLength =
        this.editor.getText().length <= 0
          ? 0
          : this.editor.getText().length - 1;
      return textLength + this.getMentions().length;
    },
    getEditor(editor) {
      this.editor = editor;
      this.editor.on('text-change', () => {
        this.currentEditorIndex = this.calcIndexWithMentions();
      });
      this.editor.on('selection-change', (selection) => {
        if (selection) {
          this.currentEditorIndex = selection.index;
        }
      });
    },
    toggleDisable(disable) {
      this.disabledByContent = disable.disabled;
      switch (disable.reason) {
        case PostDisabledReason.CONTENT:
          this.disabledTooltip = this.$t('post_disabled_bad_content');
          break;
        case PostDisabledReason.LENGTH:
          this.disabledTooltip = this.$t('post_disabled_length');
          break;
        default:
          return;
      }
    },
  },
};
