
import { isMobileResponsive } from '@/utils/mixins';
import { mapActions } from 'vuex';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';

export default {
  name: 'MultipleImageDisplay',
  components: {
    CloseIcon,
  },
  mixins: [isMobileResponsive],
  props: {
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    allowCarousel: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showImgViewer: false,
      displayedImgUri: '',
    };
  },
  computed: {
    hasMultipleImages() {
      return (this.images?.length ?? 0) > 1;
    },
    firstImage() {
      return this.images[0];
    },
    maxImageHeight() {
      return this.isMobileResponsive ? 350 : 400;
    },
  },
  methods: {
    ...mapActions('modals', ['showImageSliderModal']),
    handleCarouselModal(index) {
      this.showImageSliderModal({ navigateTo: index, images: this.images });
    },
    handleShowImgViewer(uri) {
      this.displayedImgUri = uri;
      this.showImgViewer = true;
    },
    handleCloseImgViewer() {
      this.showImgViewer = false;
      this.displayedImgUri = '';
    },
  },
};
