import { showErrorToast, showToast } from '~/utils/toast-notifications';

import { ActionsCreator } from '@/store/utils';

export default {
  addPortfolioItem(context, payload) {
    context.commit('ADD_PORTFOLIO_ITEM_INIT');
    return this.dispatch('api/addPortfolioItem', payload)
      .then((res) => {
        window.$nuxt.$notify.success(window.$nuxt.$t('item_add_ok'));
        context.commit('ADD_PORTFOLIO_ITEM_SUCCESS', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('operation_transaction_error_message'));
        context.commit('ADD_PORTFOLIO_ITEM_ERROR', err);
      });
  },
  createOperation(context, payload) {
    context.commit('CREATE_OPERATION_START');
    return this.dispatch('api/createOperation', payload)
      .then((res) => {
        window.$nuxt.$notify.success(window.$nuxt.$t('operation_add_ok'));
        context.commit('CREATE_OPERATION_SUCCESS', res.data);
      })
      .catch((err) => {
        if (err.statusCode === 400 && err.errorCode === 'wal_400006') {
          window.$nuxt.$notify.error(
            window.$nuxt.$t('operation_add_error_no_shares_at_date')
          );
        } else if (err.statusCode === 400 && err.errorCode === 'wal_400014') {
          window.$nuxt.$notify.error(
            window.$nuxt.$t('operation_add_error_not_enough_cash')
          );
        } else {
          window.$nuxt.$notify.error(
            window.$nuxt.$t('operation_add_error_general')
          );
        }
        context.commit('CREATE_OPERATION_ERROR', err);
      });
  },
  createCashOperation(context, payload) {
    context.commit('CREATE_CASH_OPERATION_START');
    return this.dispatch('api/createCashOperation', payload)
      .then((res) => {
        window.$nuxt.$notify.success(window.$nuxt.$t('operation_add_ok'));
        context.commit('CREATE_CASH_OPERATION_SUCCESS', res.data);
      })
      .catch((err) => {
        window.$nuxt.$notify.error(
          window.$nuxt.$t('operation_add_error_general')
        );
        context.commit('CREATE_CASH_OPERATION_ERROR', err);
      });
  },
  getTickerStockValue(context, payload) {
    context.commit('GET_TICKER_STOCK_VALUE_INIT');
    return this.dispatch('api/getTickerStockValue', payload)
      .then((res) => {
        context.commit('GET_TICKER_STOCK_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_STOCK_VALUE_INIT_ERROR', err);
      });
  },

  getSimilarTickers(context, payload) {
    context.commit('GET_SIMILAR_TICKERS_INIT');
    return this.dispatch('api/getSimilarTickers', payload)
      .then((res) => {
        context.commit('GET_SIMILAR_TICKERS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_SIMILAR_TICKERS_INIT_ERROR', err);
      });
  },

  getCryptoTickerStockRangeValue(context, payload) {
    context.commit('GET_TICKER_VALUE_INIT');
    return this.dispatch('api/getCryptoTickerStockRangeValue', payload)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_INIT_ERROR', err);
      });
  },

  getTickerStockRangeValue(context, payload) {
    const { noSkeletons, ...rest } = payload ?? {};

    if (!noSkeletons) context.commit('GET_TICKER_VALUE_INIT');

    return this.dispatch('api/getTickerStockRangeValue', rest)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_INIT_ERROR', err);
      });
  },

  getTickerStockRangeWithOverall(context, payload) {
    const { noSkeletons, ...rest } = payload ?? {};

    if (!noSkeletons) context.commit('GET_TICKER_VALUE_WITH_PERFORMANCE_INIT');

    return this.dispatch('api/getTickerStockRangeValue', rest)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_WITH_PERFORMANCE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_WITH_PERFORMANCE_ERROR', err);
      });
  },

  resetTickerStockRangeWithOverallData(context) {
    context.commit('GET_TICKER_VALUE_WITH_PERFORMANCE_INIT');
  },

  getMultipleTickerStockRangeValue(context, payload) {
    return this.dispatch('api/getMultipleTickerStockRangeValue', payload)
      .then((res) => {
        context.commit('GET_MULTIPLE_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_MULTIPLE_TICKER_VALUE_INIT_ERROR', err);
      });
  },

  getMultipleTickerStockRangeValueWithOverall(context, payload) {
    return this.dispatch('api/getMultipleTickerStockRangeValue', payload)
      .then((res) => {
        context.commit(
          'GET_MULTIPLE_TICKER_VALUE_WITH_PERFORMANCE_SUCCESS',
          res.data
        );
      })
      .catch((err) => {
        context.commit('GET_MULTIPLE_TICKER_VALUE_WITH_PERFROMANCE_ERROR', err);
      });
  },

  getTeamPortfolioProfiles(context, payload) {
    context.commit('GET_TEAM_PORTFOLIO_PROFILES_INIT');
    return this.dispatch('api/getTeamPortfolioProfiles', payload)
      .then((res) => {
        context.commit('GET_TEAM_PORTFOLIO_PROFILES_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TEAM_PORTFOLIO_PROFILES_INIT_ERROR', err);
      });
  },

  updateTeamPortfolioProfile(context, payload) {
    context.commit('UPDATE_TEAM_PORTFOLIO_PROFILE_INIT');
    return this.dispatch('api/updateTeamPortfolioProfile', payload)
      .then((res) => {
        context.commit('UPDATE_TEAM_PORTFOLIO_PROFILE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('UPDATE_TEAM_PORTFOLIO_PROFILE_ERROR', err);
      });
  },

  deleteTeamPortfolioProfile(context, payload) {
    context.commit('DELETE_TEAM_PORTFOLIO_PROFILE_INIT');
    return this.dispatch('api/deleteTeamPortfolioProfile', payload)
      .then((res) => {
        context.commit('DELETE_TEAM_PORTFOLIO_PROFILE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('DELETE_TEAM_PORTFOLIO_PROFILE_ERROR', err);
      });
  },

  createTeamPortfolioProfile(context, payload) {
    context.commit('CREATE_TEAM_PORTFOLIO_PROFILE_INIT');
    return this.dispatch('api/createTeamPortfolioProfile', payload)
      .then((res) => {
        context.commit('CREATE_TEAM_PORTFOLIO_PROFILE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('CREATE_TEAM_PORTFOLIO_PROFILE_ERROR', err);
      });
  },

  initPortfolioSettings(context) {
    context.commit('PORTFOLIO_SETTINGS_INIT');
  },
  updatePortfolio(context, payload) {
    context.commit('UPDATE_PORTFOLIO_INIT');
    return this.dispatch('api/updatePortfolio', payload)
      .then((res) => {
        context.commit('UPDATE_PORTFOLIO_SUCCESS', res.data);
        window.$nuxt.$notify.success(window.$nuxt.$t('portfolio_update_ok'));
        return res;
      })
      .catch((err) => {
        context.commit('UPDATE_PORTFOLIO_ERROR', err);
      });
  },

  deletePortfolio(context, payload) {
    context.commit('DELETE_PORTFOLIO_INIT');
    return this.dispatch('api/removePortfolio', payload)
      .then((res) => {
        context.commit('DELETE_PORTFOLIO_SUCCESS', res.data);
        showToast(window.$nuxt.$t('delete_portfolio_success'));
      })
      .catch((err) => {
        context.commit('DELETE_PORTFOLIO_ERROR', err);
        showErrorToast(window.$nuxt.$t('delete_portfolio_failed'));
      });
  },

  likePortfolio(context, payload) {
    return this.dispatch('api/likePortfolio', payload)
      .then((res) => {
        context.commit('LIKE_PORTFOLIO_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('LIKE_PORTFOLIO_ERROR', err);
      });
  },

  togglePortfolioCompleteView(context, payload) {
    return this.dispatch('api/toggleCompleteView', payload).then((res) => {
      context.commit('TOGGLE_COMPLETE_VIEW', res.data);
    });
  },

  // Portfolio Favorite
  getFavoritePortfolio(context, payload) {
    context.commit('GET_FAVORITE_PORTFOLIO_INIT');
    return this.dispatch('api/getFavoritePortfolio', payload).then((res) => {
      context.commit('GET_FAVORITE_PORTFOLIO_SUCCESS', res.data);
    });
    // TODO : Handle errors
  },
  favoritePortfolio(context, payload) {
    context.commit('FAVORITE_PORTFOLIO_INIT');
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_PORTFOLIO_SUCCESS', {
        id: payload,
        data: res.data,
      });
    });
    // TODO : Handle errors
  },
  getMyFavoritePortfolios(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.myFavoritePortfolios.data === null)
      context.commit('SET_MY_FAVORITE_PORTFOLIOS_LOADING', true);

    return this.dispatch('api/getMyFavoritePortfolios', payload)
      .then((res) => {
        context.commit('GET_MY_FAVORITE_PORTFOLIOS_SUCCESS', res.data);
      })
      .finally(() => {
        context.commit('GET_MY_FAVORITE_PORTFOLIOS_LOADING', false);
      });
    // TODO : Handle errors
  },

  getPortfolioTopGainers(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.portfolioTopGainers.data === null)
      context.commit('SET_PORTFOLIOS_TOP_GAINERS_LOADING', true);

    return this.dispatch('api/getPortfolioTopGainers', {
      limit: 10,
      page: 1,
    })
      .then((res) => {
        context.commit('GET_PORTFOLIOS_TOP_GAINERS_SUCCESS', res.data.data);
      })
      .catch((err) => {
        context.commit('GET_PORTFOLIOS_TOP_GAINERS_ERROR', err);
      });
  },
  likePortfolioTopGainer(context, payload) {
    return this.dispatch('api/likePortfolio', payload).then((res) => {
      context.commit('LIKE_PORTFOLIO_TOP_GAINER_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },
  favoritePortfolioTopGainer(context, payload) {
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_PORTFOLIO_TOP_GAINER_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },
  getPortfolioMonthlyTopGainers(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.monthlyPortfolioTopGainers.data === null)
      context.commit('SET_MONTHLY_PORTFOLIO_TOP_GAINERS_LOADING', true);

    return this.dispatch('api/getPortfolioMonthlyTopGainers', {
      limit: 25,
      page: 1,
    })
      .then((res) => {
        context.commit('GET_MONTHLY_PORTFOLIO_TOP_GAINERS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_MONTHLY_PORTFOLIO_TOP_GAINERS_ERROR', err);
      });
  },
  favoritePortfolioMonthlyTopGainer(context, payload) {
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_PORTFOLIO_MONTHLY_TOP_GAINER_SUCCESS', {
        id: payload,
        socials: res.data,
      });
      // TODO : handle errors
    });
  },
  likePortfolioMonthlyTopGainer(context, payload) {
    return this.dispatch('api/likePortfolio', payload).then((res) => {
      context.commit('LIKE_PORTFOLIO_MONTHLY_TOP_GAINER_SUCCESS', {
        id: payload,
        socials: res.data,
      });
      // TODO : handle errors
    });
  },
  // Profile Portfolios
  getProfilePortfolios(context, payload) {
    context.commit('GET_PROFILE_PORTFOLIOS_INIT');
    return this.dispatch('api/getProfilePortfolios', payload)
      .then((res) => {
        context.commit('GET_PROFILE_PORTFOLIOS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_PROFILE_PORTFOLIOS_ERROR', err);
      });
  },
  likeProfilePortfolio(context, payload) {
    return this.dispatch('api/likePortfolio', payload.portfolioId).then(
      (res) => {
        if (payload.isMyPortfolio) {
          context.commit('LIKE_MY_PORTFOLIO_SUCCESS', {
            id: payload.portfolioId,
            socials: res.data,
          });
        } else {
          context.commit('LIKE_PROFILE_PORTFOLIO_SUCCESS', {
            id: payload.portfolioId,
            socials: res.data,
          });
        }
      }
    );
    // TODO : handle error
  },
  likeMyPortfolio(context, payload) {
    return this.dispatch('api/likePortfolio', payload).then((res) => {
      context.commit('LIKE_MY_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
    // TODO : handle error
  },
  favoriteProfilePortfolio(context, payload) {
    return this.dispatch('api/favoritePortfolio', payload).then((res) => {
      context.commit('FAVORITE_PROFILE_PORTFOLIO_SUCCESS', {
        id: payload,
        socials: res.data,
      });
    });
  },
  /// Portfolio Comments
  getReplies(context, payload) {
    context.commit('INIT_PAGE');
    context.commit('SET_REPLIES_LOADING', true);
    return this.dispatch('api/getPortfolioComments', payload)
      .then((res) => {
        context.commit('SET_REPLIES', res.data);
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_REPLIES_LOADING', false);
      });
  },
  getNextReplies(context, payload) {
    context.commit('SET_NEXT_REPLIES_LOADING', true);
    return this.dispatch('api/getPortfolioComments', payload)
      .then((res) => {
        context.commit('APPEND_REPLIES', res.data);
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_REPLIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_REPLIES_LOADING', false);
      });
  },
  incrementPortfolioReplyCount(context) {
    context.commit('INCREMENT_COMMENT_COUNT');
  },
  incrementPortfolioRepostCount(context) {
    context.commit('INCREMENT_PORTFOLIO_REPOST_COUNT');
  },
  favoriteReply(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  likeReply(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  likeNestedReply(context, payload) {
    this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          replyId: payload.replyId,
          nestedReply: res.data,
        });
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  incrementPostReplyCount(context) {
    context.commit('INCREMENT_POST_REPLYCOUNT');
  },

  incrementNestedReplyReplyCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPLYCOUNT', payload);
  },
  incrementReplyReplyCount(context, payload) {
    context.commit('INCREMENT_REPLY_REPLYCOUNT', payload.postId);
  },
  incrementPostRepostCount(context) {
    context.commit('INCREMENT_POST_REPOSTCOUNT');
  },
  incrementReplyRepostCount(context, payload) {
    context.commit('INCREMENT_REPLY_REPOSTCOUNT', payload.postId);
  },
  incrementNestedReplyRepostCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPOSTCOUNT', payload);
  },
  appendNewReply(context, data) {
    context.commit('APPEND_NEW_REPLY', data);
  },
  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_REPLY', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  getNestedReplies(context, payload) {
    context.commit('SET_REPLIES_LOADING', true);
    this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0)
          context.commit('SET_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        context.commit('SET_NESTED_REPLY_VIEW_MORE', {
          postId: payload.postId,
          show: res.data.length == payload.params.limit,
        });
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  getNextNestedReplies(context, payload) {
    this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0)
          context.commit('APPEND_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        context.commit('SET_NESTED_REPLY_VIEW_MORE', {
          postId: payload.postId,
          show: res.data.length == payload.params.limit,
        });
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  appendNewNestedReply(context, payload) {
    const parentReply = context.state.replies.find(
      (r) => r.postId == payload.postId
    );
    if (parentReply.showLoadMore) return;

    context.commit('APPEND_NEW_NESTED_REPLY', payload);
  },
  setRepliesLoading(context, isLoading) {
    context.commit('SET_REPLIES_LOADING', isLoading);
  },
  setIsLoadFinished(context, isLoadFinished) {
    context.commit('SET_IS_LOAD_FINISHED', isLoadFinished);
  },
  setInitialIntervalItemsGain(context) {
    context.commit('SET_INITIAL_INTERVAL_ITEMS_GAIN');
  },
  getPortfolioItemsGainForInterval(context, payload) {
    context.commit('ITEMS_GAIN_FOR_INTERVAL_INIT');
    return this.dispatch('api/getPortfolioItemsGainForInterval', payload)
      .then((res) => {
        context.commit('HANDLE_ADD_ITEMS_GAIN_FOR_INTERVAL', {
          data: res.data,
          intervalKey: payload.intervalKey,
        });
      })
      .catch((err) => {
        context.commit('SET_ITEMS_GAIN_FOR_INTERVAL_ERROR', err);
      });
  },
  updatePortfolioItemsGainValue(context, intervalKey) {
    context.commit('UPDATE_PORTFOLIO_ITEMS_GAIN_VALUE', intervalKey);
  },

  // Portfolio search
  portfolioSearch(context, payload) {
    context.commit('PORTFOLIO_SEARCH_INIT');
    return this.dispatch('api/portfolioSearch', payload)
      .then((res) => {
        context.commit('PORTFOLIO_SEARCH_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('PORTFOLIO_SEARCH_ERROR', err);
      });
  },
  // Portfolios To Compare
  resetPortfolioToCompare(context) {
    context.commit('PORTFOLIO_TO_COMPARE_INIT');
  },
  resetPortfolioSearch(context) {
    context.commit('PORTFOLIO_SEARCH_INIT');
  },
  addPortfoliosToCompare(context, payload) {
    context.commit('ADD_PORTFOLIOS_TO_COMPARE', payload);
  },
  removePortfolioFromComparison(context, portfolioId) {
    context.commit('REMOVE_PORTFOLIO_TO_COMPARE', portfolioId);
  },
  removeAllPortfolioFromComparison(context) {
    context.commit('REMOVE_ALL_PORTFOLIOS_FROM_COMPARISON');
  },
  getPortfolioComparisonGraphInfo(context, payload) {
    return this.dispatch('api/getPortfolioComparisonGraphInfo', payload)
      .then((res) => {
        context.commit('GET_PORTFOLIO_COMPARISON_GRAPH_INFO_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_PORTFOLIO_COMPARISON_GRAPH_INFO_ERROR', err);
      });
  },

  createPortfolio(context, payload) {
    context.commit('CREATE_PORTFOLIO_INIT');
    return this.dispatch('api/createPortfolio', payload)
      .then((res) => {
        context.commit('CREATE_PORTFOLIO_SUCCESS', res.data);
        if (payload.invitationId) {
          this.dispatch('leagues/answerLeagueInvitation', {
            leagueInvitationId: payload.invitationId,
            invitationAnswer: true,
          });
        }
      })
      .catch((err) => {
        context.commit('CREATE_PORTFOLIO_ERROR', err);
      });
  },
  getMyPortfolios(context, payload) {
    const { noSkeletons } = payload ?? {};

    // NOTE: With this in place, we only show skeletons if we don't explicitly specify that we don't want skeletons,
    // AND the related data hasn't ALREADY been loaded.
    // This is to prevent unnecessary skeleton showing if the data has already been loaded.
    // Instead, we simply replace the existing data and the UI updates accordingly.
    if (!noSkeletons && context.state.getMyPortfolios.data === null)
      context.commit('SET_GET_MY_PORTFOLIOS_LOADING', true);

    return this.dispatch('api/getMyPortfolios')
      .then((res) => {
        context.commit('GET_MY_PORTFOLIOS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_MY_PORTFOLIOS_ERROR', err);
      });
  },
  getPortfolioById(context, payload) {
    context.commit('GET_PORTFOLIO_BY_ID_INIT');
    return this.dispatch('api/getPortfolioById', payload)
      .then((res) => {
        context.commit('GET_PORTFOLIO_BY_ID_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_PORTFOLIO_BY_ID_ERROR', err);
      });
  },

  ...ActionsCreator([
    {
      name: 'addFavoritePortfolio',
      mutation: 'FAVORITE_PORTFOLIO',
    },
    {
      name: 'removeFavoritePortfolio',
      mutation: 'FAVORITE_PORTFOLIO',
    },
    {
      name: 'orderPortfolios',
      mutation: 'ORDER_PORTFOLIOS',
    },
    {
      name: 'getPortfolioGraph',
      mutation: 'GET_PORTFOLIO_GRAPH',
    },
    {
      name: 'removePortfolioItem',
      mutation: 'REMOVE_PORTFOLIO_ITEM',
    },
    {
      name: 'removePortfolioCashItem',
      mutation: 'REMOVE_PORTFOLIO_CASH_ITEM',
    },
  ]),
};
