
import { mapGetters, mapActions } from 'vuex';
import UnrollerList from '~/components/ui/molecule/UnrollerList.vue';
import HelpLink from '~/components/ui/atom/HelpLink.vue';
import HelpModals from '~/components/ui/helpCenter/HelpModals.vue';
import { ComponentIds } from '@/utils/componentIds';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import { isMobile, isDesktop } from '~/utils/screenSizes';
import { appURLs, inAppRedirect } from '~/utils/route-redirects';

export default {
  name: 'HelpCenterWrapper',
  components: { UnrollerList, HelpLink, HelpModals, CloseIcon },
  data() {
    return {
      mainCoordX: 0,
      mainWidth: 400,
      mainMaxWidth: 400,
      walkthroughCoordX: 0,
      feedCoordX: 0,
      settingsCoordX: 0,
      skadeGuideLinks: Array(3)
        .fill(null)
        .map((_, index) => ({
          text: this.$t(`help_center_guide_link_${index + 1}_text`),
          uri: this.$t(`help_center_guide_link_${index + 1}_uri`),
        })),
    };
  },
  computed: {
    ...mapGetters('modals', ['helpCenterObjectRes']),
    isMobileSize() {
      return isMobile();
    },
    isDesktopSize() {
      return isDesktop();
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions('modals', ['setHelpCenterObjectRes']),
    ...mapActions('modals', ['showPortfolioEmptyStateModal']),
    async waitForFirstPost() {
      while (!document.getElementById(ComponentIds.FirstPostFromFeed)) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    },
    async handleResize() {
      // When browser changes size, adapt X coordinates
      // For the main modal
      const baseElement = this.getBaseElement();
      if (baseElement !== null && this.isMobileSize) {
        this.mainCoordX = baseElement.getBoundingClientRect().right + 10;
        this.mainMaxWidth = window.innerWidth - this.mainCoordX - 5;

        const widgetElement = document.getElementById(
          ComponentIds.WidgetsContainerId
        );
        if (widgetElement !== null) {
          const widgetWidth =
            widgetElement.getBoundingClientRect().right -
            widgetElement.getBoundingClientRect().left;
          this.mainWidth = widgetWidth + 40;
        }
      } else {
        this.mainMaxWidth = window.innerWidth;
      }

      // For walkthrough modal
      if (this.helpCenterObjectRes.showWalkthrough === 1) {
        await this.waitForFirstPost();
        const firstPost = document.getElementById(
          ComponentIds.FirstPostFromFeed
        );
        firstPost.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        this.walkthroughCoordX =
          window.innerWidth - firstPost.getBoundingClientRect().left;
      } else if (this.helpCenterObjectRes.showWalkthrough === 2) {
        const feedStartAPost = document.getElementById(
          ComponentIds.FeedStartAPost
        );
        this.walkthroughCoordX = feedStartAPost.getBoundingClientRect().left;
      }

      // For feed modal
      if (this.helpCenterObjectRes.showFeed === 1) {
        const searchbarElement = document.getElementById(
          ComponentIds.SearchbarId
        );
        this.feedCoordX =
          window.innerWidth -
          searchbarElement.getBoundingClientRect().right +
          115;
      }

      // For settings modal
      if (this.helpCenterObjectRes.showSettings) {
        if (baseElement !== null) {
          this.settingsCoordX =
            baseElement.getBoundingClientRect().left -
            (this.isDesktopSize ? 110 : 20); // adding a little offset
        }
      }
    },
    closeHelpCenter() {
      const payload = { ...this.helpCenterObjectRes };
      payload.show = false;
      payload.showWalkthrough = 0;
      payload.showFeed = 0;
      this.setHelpCenterObjectRes(payload);
    },
    getWelcomeModalContent() {
      const toReturn = [
        {
          name: this.$t('help_general_walkthrough'),
          description: this.$t('help_general_walkthrough_content'),
          icon: 'Question',
          emit: 'onHelpWalkthrough',
          path: appURLs.feed(),
        },
        {
          name: this.$t('help_portfolio'),
          description: this.$t('help_portfolio_content'),
          icon: 'Portfolio',
          emit: 'onHelpPortfolio',
          path: appURLs.portfolio(),
        },
        {
          name: this.$t('help_feed'),
          description: this.$t('help_feed_content'),
          icon: 'Home',
          emit: 'onHelpFeed',
          path: appURLs.feed(),
        },
      ];
      if (!this.isMobileSize) {
        toReturn.push({
          name: this.$t('help_settings'),
          description: this.$t('help_settings_content'),
          icon: 'Settings',
          emit: 'onHelpSettings',
          path: appURLs.feed(),
        });
      }
      return toReturn;
    },
    handleOnHelpWalkthrough() {
      const payload = { ...this.helpCenterObjectRes };
      payload.showWalkthrough = 1;
      if (!this.$route.name?.includes('index')) {
        payload.feedPageAvailable = false;
      }
      this.setHelpCenterObjectRes(payload);
      this.handleResize();
    },
    handleOnHelpPortfolio() {
      inAppRedirect('/portfolios');
      const payload = { ...this.helpCenterObjectRes };
      payload.showMain = false;
      payload.show = false;
      this.setHelpCenterObjectRes(payload);
      this.showPortfolioEmptyStateModal();
    },
    handleOnHelpFeed() {
      const payload = { ...this.helpCenterObjectRes };
      payload.showFeed = 1;
      this.setHelpCenterObjectRes(payload);
      this.handleResize();
    },
    handleOnHelpSettings() {
      const payload = { ...this.helpCenterObjectRes };
      payload.showSettings = !payload.showSettings;
      this.setHelpCenterObjectRes(payload);
      this.handleResize();
    },
    HandleNewWalkthroughStep(newStep) {
      if (newStep === 2) {
        const feedStartAPost = document.getElementById(
          ComponentIds.FeedStartAPost
        );
        // feedStartAPost.scrollIntoView({ block: 'start', behavior: 'smooth' });
        const yOffset = this.isMobileSize ? -125 : -65;
        const y =
          feedStartAPost.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        this.walkthroughCoordX = feedStartAPost.getBoundingClientRect().left;
      }
      const payload = { ...this.helpCenterObjectRes };
      payload.showWalkthrough = newStep;
      this.setHelpCenterObjectRes(payload);
    },
    HandleNewFeedStep(newStep) {
      const payload = { ...this.helpCenterObjectRes };
      payload.showFeed = newStep;
      this.setHelpCenterObjectRes(payload);
    },
    handleCloseSection() {
      const payload = { ...this.helpCenterObjectRes };
      payload.showWalkthrough = 0;
      payload.showFeed = 0;
      payload.showSettings = false;
      this.setHelpCenterObjectRes(payload);
    },
    handleOpenSection() {
      const payload = { ...this.helpCenterObjectRes };
      payload.showWalkthrough = 0;
      payload.showFeed = 0;
      payload.showSettings = false;
      this.setHelpCenterObjectRes(payload);
    },

    showMainModal() {
      if (this.helpCenterObjectRes.showMain) {
        if (!this.isMobileSize) {
          return true;
        }
        if (
          this.helpCenterObjectRes.showFeed == 0 &&
          this.helpCenterObjectRes.showWalkthrough == 0
        ) {
          return true;
        }
      }
      return false;
    },
    getMainStyle() {
      let toReturn = '';
      if (this.isMobileSize || this.mainMaxWidth < 270) {
        return 'right: 0; top: 0; width: 100%; height: 100%';
      } else {
        toReturn += 'top: 4rem;';
        toReturn += 'height: calc(100% - 4.5rem);';
        const indexPageElement = document.getElementById('indexPage');

        if (indexPageElement) {
          toReturn += `right: ${
            indexPageElement.getBoundingClientRect().right
          }px !important; left: ${
            indexPageElement.getBoundingClientRect().right - 400
          }px !important; position: absolute !important; `;
        }
        if (this.mainMaxWidth >= 270) {
          toReturn += 'max-width:' + this.mainMaxWidth + 'px;';
        }
        if (this.mainWidth >= 270) {
          toReturn += 'width:' + this.mainWidth + 'px;';
        }
        return toReturn;
      }
    },
    // Get element from which main modal will base its coordinates and width
    getBaseElement() {
      let id = '';
      const path = this.$route.fullPath;
      const routeName = this.$route.name?.split('_')[0];
      if (routeName?.includes(appURLs.feed())) {
        id = ComponentIds.FeedStartAPost;
      } else if (path.includes(appURLs.profile())) {
        id = ComponentIds.ProfileBoxId;
      } else if (path.includes(appURLs.market())) {
        id = ComponentIds.MarketFeedContainerId;
      } else if (path.includes(appURLs.crypto())) {
        id = ComponentIds.CryptoFeedContainerId;
      } else if (path.includes(appURLs.saved())) {
        id = ComponentIds.FavoriteContainerId;
      } else if (path.includes(appURLs.messages())) {
        id = ComponentIds.MessagesContainerId;
      }
      if (id === '') {
        return null;
      }
      return document.getElementById(id);
    },
  },
};
