
import { optimizeImg } from '@/utils';
export default {
  name: 'ProfilePicture',
  emits: ['onImageSelect', 'onClose'],
  data() {
    return {
      imgSrc: '',
      fileName: '',
      isHoveringPicture: false,
    };
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      if (file) {
        this.fileName = file.name;
        if (!file.type.includes('image/')) {
          alert('Merci de sélectionner un fichier image.');
        } else if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          alert(this.$t('browser_do_note_support'));
        }
      } else {
        this.imgSrc = '';
        this.$refs.inputFile.value = '';
      }
    },
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const file = new File([blob], this.fileName);
        const res = await optimizeImg(file);

        this.$emit('onImageSelect', res);

        this.clearInputFile();
      });
    },
    clearInputFile() {
      this.imgSrc = '';
      this.$refs.inputFile.value = '';
      this.$emit('onClose');
    },
  },
};
