import { render, staticRenderFns } from "./UINewsPost.vue?vue&type=template&id=bdf16d0c"
import script from "./UINewsPost.vue?vue&type=script&lang=js"
export * from "./UINewsPost.vue?vue&type=script&lang=js"
import style0 from "./UINewsPost.vue?vue&type=style&index=0&id=bdf16d0c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/skade-pwa/components/ui/atom/Img.vue').default,Video: require('/skade-pwa/components/ui/atom/Video.vue').default,Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,Card: require('/skade-pwa/components/ui/atom/Card.vue').default})
