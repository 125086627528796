
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Poll',
  props: {
    pollId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pollDefinition: {
        title: '',
        options: [],
      },
      selectedOption: null,
      hasVoted: false,
      showMessageAfterVoting: false,
      userVotedOptionId: null,
    };
  },
  computed: {
    ...mapGetters('post', ['poll']),
    poll() {
      return this.$store.state.post.polls[this.pollId] || [];
    },
    totalVotes() {
      return this.pollDefinition.options.reduce(
        (sum, option) => sum + option.votes,
        0
      );
    },
    pollWithPercentage() {
      if (
        !this.pollDefinition?.options ||
        this.pollDefinition.options.length === 0
      ) {
        return [];
      }

      const mostVotedOptionIds = this.getOptionIdWithMostVotes();

      return this.pollDefinition.options.map((option) => {
        const percentage = this.totalVotes
          ? (option.votes / this.totalVotes) * 100
          : 0;
        return {
          ...option,
          percentage: percentage.toFixed(0),
          isMostVoted: mostVotedOptionIds.includes(option.id),
        };
      });
    },
  },

  watch: {
    poll: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.pollDefinition = {
            title: newVal[0].title,
            options: newVal.map((p) => ({
              id: p.id,
              text: p.option,
              votes: p.votes,
            })),
          };
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.pollId) {
      try {
        await this.getPoll({ id: this.pollId });
        const votedResult = await this.getPollResults({ id: this.pollId });
        this.hasVoted = votedResult;

        const currentPoll = this.poll?.filter((p) => p.pollId === this.pollId);
        this.pollDefinition = {
          // TODO Fix the assumption that the poll will have a title.
          // I keep getting errors that the title is actually null.
          title: currentPoll[0].title,
          options: currentPoll.map((p) => ({
            id: p.id,
            text: p.option,
            votes: p.votes,
          })),
        };
      } catch (error) {
        console.error('Error fetching poll data:', error);
      }
    }
  },
  methods: {
    ...mapActions('post', ['getPoll', 'getPollResults', 'votePoll']),
    async submitVote() {
      if (this.selectedOption) {
        const option = this.pollDefinition.options.find(
          (p) => p.id === this.selectedOption
        );
        if (option) {
          await this.votePoll({ id: option.id });
          this.hasVoted = true;
          this.showMessageAfterVoting = true;
          this.userVotedOptionId = option.id;

          this.pollDefinition.options = this.pollDefinition.options.map(
            (opt) => {
              if (opt.id === option.id) {
                return { ...opt, votes: opt.votes + 1 };
              }
              return opt;
            }
          );

          this.$forceUpdate();
        }
      }
    },
    getPercentage(votes) {
      return this.totalVotes ? ((votes / this.totalVotes) * 100).toFixed(0) : 0;
    },
    getOptionIdWithMostVotes() {
      const highestVotes = Math.max(
        ...this.pollDefinition.options.map((option) => option.votes)
      );
      return this.pollDefinition.options
        .filter((option) => option.votes === highestVotes)
        .map((option) => option.id);
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
