
import { isMobileResponsive } from '@/utils/mixins';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';

export default {
  name: 'UIImageViewer',
  components: { CloseIcon },
  mixins: [isMobileResponsive],
  props: {
    imgUri: {
      required: true,
      type: String,
    },
  },
  computed: {},
  created() {
    const body = document.body;
    body.classList.add('overflow-y-hidden');
  },
  beforeDestroy() {
    const body = document.body;
    body.classList.remove('overflow-y-hidden');
  },
};
