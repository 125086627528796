import { isPortfolioOwner } from '@/utils/portfolio-role-checks';

export const createPortfolioRes = (state) => state.createPortfolio;
export const orderPortfoliosRes = (state) => state.orderPortfolios;
export const getPortfolioByIdRes = (state) => state.getPortfolioById;
export const removePortfolioRes = (state) => state.removePortfolio;
export const updatePortfolioRes = (state) => state.updatePortfolio;
export const getPortfolioGraphRes = (state) => state.getPortfolioGraph;
export const getMyPortfoliosRes = (state) => state.getMyPortfolios;
export const myOwnedPortfolios = (state) =>
  state.getMyPortfolios?.data?.portfolios.filter((p) =>
    isPortfolioOwner(p.portfolioUserRole)
  ) ?? [];
export const mySharedPortfolios = (state) =>
  state.getMyPortfolios?.data?.portfolios.filter(
    (p) => !isPortfolioOwner(p.portfolioUserRole)
  ) ?? [];

export const createOperationRes = (state) => state.createOperation;
export const createCashOperationRes = (state) => state.createCashOperation;
export const addPortfolioItemRes = (state) => state.addPortfolioItem;
export const removePortfolioItemRes = (state) => state.removePortfolioItem;
export const removePortfolioCashItemRes = (state) =>
  state.removePortfolioCashItem;
export const tickerToAddRes = (state) => state.tickerToAdd;
export const getTickerStockValueRes = (state) => state.getTickerStockValue;
export const getSimilarTickersRes = (state) => state.getSimilarTickers;
export const getTickerStockRangeValueRes = (state) =>
  state.getTickerStockRangeValue;
export const getTickerStockRangeWithOverallRes = (state) =>
  state.getTickerStockRangeWithOverall;
export const getMultipleTickerStockRangeValueRes = (state) =>
  state.getMultipleTickerStockRangeValue;
export const getMultipleTickerStockRangeValueWithOverallRes = (state) =>
  state.getMultipleTickerStockRangeValueWithOverall;
export const getTeamPortfolioProfilesRes = (state) =>
  state.getTeamPortfolioProfilesValue;
export const updateTeamPortfolioProfileRes = (state) =>
  state.updateTeamPortfolioProfileValue;
export const deleteTeamPortfolioProfileRes = (state) =>
  state.deleteTeamPortfolioProfileValue;
export const createTeamPortfolioProfileRes = (state) =>
  state.createTeamPortfolioProfileValue;
export const myFavoritePortfolios = (state) => state.myFavoritePortfolios;
export const portfolioTopGainers = (state) => state.portfolioTopGainers;
export const monthlyPortfolioTopGainers = (state) =>
  state.monthlyPortfolioTopGainers;
export const profilePortfolios = (state) => state.profilePortfolios.data;
export const isFavoritePortfolio = (state) => state.isFavoritePortfolio;
export const replies = (state) => state.replies;
export const repliesLoading = (state) => state.repliesLoading;
export const nextRepliesLoading = (state) => state.nextRepliesLoading;
export const nestedRepliesLoading = (state) => state.nestedRepliesLoading;
export const nextNestedRepliesLoading = (state) =>
  state.nextNestedRepliesLoading;
export const repliesError = (state) => state.repliesError;
export const isLoadFinished = (state) => state.isLoadFinished;
export const portfolioSearchRes = (state) => state.portfolioSearch.data;
export const portfoliosToCompareRes = (state) => state.portfoliosToCompare;
export const getPortfolioComparisonGraphInfoRes = (state) =>
  state.getPortfolioComparisonGraphInfo;
export const portfolioItemsGainByIntervalsRes = (state) =>
  state.portfolioItemsGainByIntervals.data;
