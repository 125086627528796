import { render, staticRenderFns } from "./UICreateComment.vue?vue&type=template&id=ae1a4864"
import script from "./UICreateComment.vue?vue&type=script&lang=js"
export * from "./UICreateComment.vue?vue&type=script&lang=js"
import style0 from "./UICreateComment.vue?vue&type=style&index=0&id=ae1a4864&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImg: require('/skade-pwa/components/ui/atom/ProfileImg.vue').default,Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,ImageCropper: require('/skade-pwa/components/ui/atom/ImageCropper.vue').default})
