
import UIPost from '@/components/ui/organism/UIPost';
import CreateCommentWrapper from '@/components/socials/CreateCommentWrapper';
import Modal from '@/components/ui/molecule/Modal';

export default {
  name: 'UICreatePostModal',
  components: { UIPost, CreateCommentWrapper, Modal },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    profileImg: {
      type: String,
      required: true,
    },
    profileName: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploadImage: {
      type: Function,
      required: true,
    },
    searchMentions: {
      type: Function,
      required: true,
    },
    searchHashtags: {
      type: Function,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'create_post',
    },
    mentionedPost: {
      type: Object,
      default: () => null,
    },
    originalPost: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
