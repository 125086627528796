import { render, staticRenderFns } from "./UIImageViewer.vue?vue&type=template&id=25b7c242&scoped=true"
import script from "./UIImageViewer.vue?vue&type=script&lang=js"
export * from "./UIImageViewer.vue?vue&type=script&lang=js"
import style0 from "./UIImageViewer.vue?vue&type=style&index=0&id=25b7c242&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b7c242",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/skade-pwa/components/ui/atom/Img.vue').default})
