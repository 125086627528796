import { render, staticRenderFns } from "./MultipleImageDisplay.vue?vue&type=template&id=3441d1d8"
import script from "./MultipleImageDisplay.vue?vue&type=script&lang=js"
export * from "./MultipleImageDisplay.vue?vue&type=script&lang=js"
import style0 from "./MultipleImageDisplay.vue?vue&type=style&index=0&id=3441d1d8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/skade-pwa/components/ui/atom/Img.vue').default,UIImageViewer: require('/skade-pwa/components/ui/atom/UIImageViewer.vue').default})
