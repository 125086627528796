import { showErrorToast, showToast } from '~/utils/toast-notifications';

export default {
  getFeed(context, payload) {
    const { noSkeletons } = payload ?? {};

    if (!noSkeletons) context.commit('GET_FEED_START');

    return this.dispatch('api/getFeed')
      .then((res) => {
        context.commit('GET_FEED_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_FEED_ERROR', err);
      });
  },
  incrementReplyCount(context, payload) {
    if (payload && payload.postId) {
      context.commit('INCREMENT_POST_REPLY_COUNT', payload.postId);
    }
  },
  likePost(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_LIKE', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  favoritePost(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST_FAVORITE', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  countNewPost(context) {
    this.$api.getFeed().then((res) => {
      context.commit('COUNT_NEW_POST_SUCCESS', res.data);
    });
  },
  getNextPosts(context) {
    context.commit('SET_NEXT_POSTS_LOADING', true);

    const cursor = context.getters.cursor;

    return this.dispatch('api/getFeed', { pagination: cursor })
      .then((res) => {
        context.commit('APPEND_POSTS_TO_FEED', res.data);
        return res;
      })
      .catch((err) => {
        context.commit('GET_FEED_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_POSTS_LOADING', false);
      });
  },
  appendNewPostAtTop(context, post) {
    context.commit('APPEND_NEW_POST_AT_TOP', post);
  },
  updatePostAfterEdit(context, post) {
    context.commit('UPDATE_POST_AFTER_EDIT', post);
  },
  /// Replies
  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_REPLY', res.data);
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.target,
        });
      });
  },
  likeReply(context, payload) {
    return this.dispatch('api/likePost', payload.replyId)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  favoriteReply(context, payload) {
    return this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  incrementReplyReplyCount(context, payload) {
    if (payload && payload.postId) {
      context.commit('INCREMENT_REPLY_REPLY_COUNT', payload);
    }
  },
  /// Nested Replies
  loadNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('SET_NESTED_REPLIES', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  loadNextNestedReplies(context, payload) {
    return this.dispatch('api/getPostReplies', {
      postId: payload.replyId,
      params: payload.params,
    })
      .then((res) => {
        context.commit('APPEND_NESTED_REPLY_BOTTOM', {
          replies: res.data,
          postId: payload.postId,
          replyId: payload.replyId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  appendNestedReplyTop(context, payload) {
    context.commit('APPEND_NESTED_REPLY_TOP', payload);
  },
  favoriteNestedReply(context, payload) {
    return this.dispatch('api/favoritePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  likeNestedReply(context, payload) {
    return this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          data: res.data,
          postId: payload.postId,
        });
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', {
          err: err,
          postId: payload.replyId,
        });
      });
  },
  incrementNestedReplyReplyCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPLY_COUNT', payload);
  },
  closeFeedWelcomeCard(context, profileId) {
    return this.dispatch('api/updateProfile', {
      profileId: profileId,
      data: { hasSeenFeedWelcomeCard: true },
    }).then((res) => {
      if (res.data.hasSeenFeedWelcomeCard) {
        this.dispatch('authentication/closeFeedWelcomeCard');
      }
    });
  },
};
